var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    width: '100vw',
    height: '400vw',
    'background-image': 'url(' + _vm.picUrl + ')',
    'background-repeat': 'no-repeat',
    'background-size': '100% 100%',
    '-moz-background-size': '100% 100%',
})},[_c('div',{staticStyle:{"position":"absolute","top":"128vw","left":"18vw"}},[_c('van-image',{staticClass:"buttonStyle",attrs:{"width":"26vw","src":_vm.np},on:{"click":function($event){return _vm.go('np')}}})],1),_c('div',{staticStyle:{"position":"absolute","top":"160vw","left":"64vw"}},[_c('van-image',{staticClass:"buttonStyle",attrs:{"width":"20vw","src":_vm.tt},on:{"click":function($event){return _vm.go('tt')}}})],1),_c('div',{staticStyle:{"position":"absolute","top":"226vw","left":"70vw"}},[_c('van-image',{staticClass:"buttonStyle",attrs:{"width":"26vw","src":_vm.wgw},on:{"click":function($event){return _vm.go('wgw')}}})],1),_c('div',{staticStyle:{"position":"absolute","top":"272vw","left":"6vw"}},[_c('van-image',{staticClass:"buttonStyle",attrs:{"width":"32vw","src":_vm.cd},on:{"click":function($event){return _vm.go('cd')}}})],1),_c('div',{staticStyle:{"position":"absolute","top":"292vw","left":"64vw"}},[_c('van-image',{staticClass:"buttonStyle",attrs:{"width":"32vw","src":_vm.hclc},on:{"click":function($event){return _vm.go('hclc')}}})],1),_c('div',{staticStyle:{"position":"absolute","top":"350vw","left":"7vw"}},[_c('van-image',{staticClass:"buttonStyle",attrs:{"width":"34vw","src":_vm.qjt},on:{"click":function($event){return _vm.go('qjt')}}})],1),_c('div',[_c('van-image',{staticClass:"elementMove",attrs:{"width":"28vw","src":_vm.leader}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }